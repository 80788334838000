<template>
<div class="flex align-content  borrow-date w100">
  <div class="borrow-date-left flex align-content">
    <div class="icon"><img :src="`${$imgUrl.freeEvent}borrow.png`" alt="" srcset="" /></div>
    <div class="m-ml-18 m-mt-10 span1">
      <div class="m-c-6b6b6b m-f-24">预计起租日</div>
      <!-- 还机详情展示起租日 -->
      <div class="m-c-9b9b9b m-f-20 m-mt-10">{{beginTime}}</div>
    </div>
  </div>
  <div class="border">
    <img :src="`${$imgUrl.freeEvent}border.png`"  alt="" srcset="">
  </div>
  <!--  -->
  <div class="borrow-date-right  flex align-content">
    <div class="icon">
      <img :src="`${$imgUrl.freeEvent}also.png`" alt="" srcset="" />
    </div>
    <div class="m-ml-18 m-mt-10 span1">
      <div class="m-c-6b6b6b m-f-24">归还日</div>
      <div class="m-c-9b9b9b m-f-20 m-mt-10">{{endTime}}</div>
      <div class="m-f-20 yanqi" v-if="discountType">延期{{discountType}}天</div>
    </div>
    <!--<div class="m-f-20 extension">延期90天</div> -->
  </div>
  <div class="m-f-20 m-mt-25" style="color:#FFA982">
      提示：快递运输期间不计入租期，实际起租日为快递签收次日
  </div>
</div>
</template>
<script setup>
import { getCurrentInstance, defineProps } from 'vue';
/* props */
defineProps({
  beginTime: String,
  endTime: String,
  discountType: Boolean || Number,
});
/* 变量 */
const {
  appContext: {
    config: { globalProperties: { $imgUrl } },
  },
} = getCurrentInstance();
</script>
<style lang="scss" scoped>
.borrow-date {
  margin-top: 10px;
  justify-content: space-between;
}

.borrow-date-left, .borrow-date-right {
  position: relative;
  width: 38%;
  border: 1px solid #F2F2F2;
  padding: 20px;
}

.borrow-date-left, .borrow-date-right {
  .icon {
    width: 80px;
    height: 80px;
    > img {
      @include img;
    }
  }
}

.extension {
  position: absolute;
  top: 0;
  right: 0;
  width: 104px;
  height: 38px;
  background: #1677FF;
  border-radius: 0px 10px 0px 10px;
  text-align: center;
  line-height: 38px;
  color: #fff;
}

.border {
  width: 38px;
  height: 9px;
  position: relative;
  // top: 50px;
  > img {
    @include img;
  }
}

.yanqi {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px;
  height: 38px;
  background: #1677FF;
  border-radius: 0px 10px 0px 10px;
  text-align: center;
  line-height: 38px;
  color: #fff;
}
</style>
